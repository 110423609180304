<template>
    <div ref="splineContainer" class="spline-container">
    </div>
</template>

<script>
import * as THREE from 'three';
import SplineLoader from '@splinetool/loader';

export default {
    name: 'SplineModel',
    emits: ['ready'],
    props: {
        mousePosition: {
            type: Object,
            default: () => ({ x: 0, y: 0 }),
        },
    },
    async mounted() {
        this.initThreeJS();
        this.loadSplineModel();
        window.addEventListener('resize', this.onWindowResize);
        setTimeout(() => {
            this.$emit('ready');
        }, 2000);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        if (this.renderer) {
            this.$refs.splineContainer.removeChild(this.renderer.domElement);
        }
    },
    methods: {
        initThreeJS() {
            const container = this.$refs.splineContainer;
            this.scene = new THREE.Scene({ alpha: true });
            this.camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
            this.camera.position.z = 1115;

            // Enable anti-aliasing by setting the antialias option to true
            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, powerPreference: 'high-performance' });

            this.renderer.setSize(container.offsetWidth, container.offsetHeight);
            container.appendChild(this.renderer.domElement);
            this.renderer.setClearColor(0x000000, 0); // Fully transparent background
            const ambientLight = new THREE.AmbientLight(0xD3D3D3, 0.2);
            this.scene.add(ambientLight);

        },
        loadSplineModel() {
            const loader = new SplineLoader();
            loader.load(
                'https://prod.spline.design/9rXLamxBnpbXNGlM/scene.splinecode',
                (splineScene) => {
                    this.scene.add(splineScene);
                    this.splineScene = splineScene;
                    splineScene.position.z += 415;

                    // Make the mesh semi-transparent
                    if (splineScene.children && splineScene.children.length > 0) {
                        splineScene.children.forEach(child => {
                            if (child.material) {
                                child.material.transparent = true;
                                child.material.opacity = 0.25; // Adjust this value for desired transparency
                                child.material.color.setHex(0x808080); // Set the color to gray
                            }
                        });
                    }

                    const directionalLight = new THREE.DirectionalLight(0xfffefe, 0.6);
                    directionalLight.castShadow = true;
                    directionalLight.target = this.splineScene;
                    directionalLight.position.set(-518.44, 353.91, 600);
                    this.scene.add(directionalLight);
                    this.scene.add(directionalLight.target);
                    this.animate();
                },
                null,
                (error) => {
                    console.error('An error happened', error);
                }
            );
        },
        animate() {
            requestAnimationFrame(this.animate);
            const cameraSpeed = 0.1; // Reduce this value to make the camera movement smoother
            this.camera.position.x += (this.mousePosition.x * 100 - this.camera.position.x) * cameraSpeed;
            this.camera.position.y += (this.mousePosition.y * 100 - this.camera.position.y) * cameraSpeed;
            this.camera.lookAt(this.scene.position);
            if (this.splineScene) {
                this.splineScene.rotation.y += 0.01;
            }
            this.renderer.render(this.scene, this.camera);
        },

        onWindowResize() {
            const container = this.$refs.splineContainer;
            this.camera.aspect = container.offsetWidth / container.offsetHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(container.offsetWidth, container.offsetHeight);
        }
    }
};
</script>

<style>
.spline-container {
    width: 100vw;
    height: 100vh;
}
</style>
