<template>
    <div class="grid-container" ref="gridContainer" @mousemove="onMouseMove">
        <div v-for="line in lines" :key="line.id" class="grid-line" :class="line.orientation" :style="getStyle(line)">
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            lines: [
                ...Array.from({ length: 10 }, (_, index) => ({
                    id: 'v' + index,
                    orientation: 'vertical',
                    position: (index + 1) * 10, // 10vw position for vertical lines
                })),
                ...Array.from({ length: 10 }, (_, index) => ({
                    id: 'h' + index,
                    orientation: 'horizontal',
                    position: (index + 1) * 10, // 10vw position for horizontal lines
                })),
            ],
            mousePosition: { x: 0, y: 0 },
        };
    },
    methods: {
        onMouseMove(event) {
            const rect = this.$refs.gridContainer.getBoundingClientRect();
            this.mousePosition.x = ((event.clientX - rect.left) / rect.width) * 100; // Percentage position relative to grid container
            this.mousePosition.y = ((event.clientY - rect.top) / rect.height) * 50; // Percentage position relative to grid container
        },
        getStyle(line) {
            const position = line.orientation === 'vertical' ? 'left' : 'top';
            const size = line.orientation === 'vertical' ? 'width' : 'height';
            const mousePosition = line.orientation === 'vertical' ? this.mousePosition.x : this.mousePosition.y;
            const distance = Math.abs(line.position - mousePosition);
            const opacity = Math.max(0, 1 - distance / 11.11); // Adjust based on grid spacing
            return {
                [position]: line.position + 'vw',
                [size]: '2px',
                background: `linear-gradient(to ${line.orientation === 'vertical' ? 'bottom' : 'right'}, transparent, rgba(128, 128, 128, ${opacity}), transparent)`,
            };
        },
    },
};
</script>
  
<style>
.grid-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    /* Adjust the width of the grid container */
    height: 100vh;
    overflow: hidden;
}

.grid-line {
    position: absolute;
    background: linear-gradient(to right, transparent, rgba(128, 128, 128, 0.5), transparent);
    transition: background 0.3s ease;
}

.grid-line.vertical {
    top: 0;
    bottom: 0;
}

.grid-line.horizontal {
    left: 0;
    right: 0;
}
</style>
