<template>
    <div v-if="isVisible" class="modal-backdrop" @click="closeModal">
        <div class="modal-content" @click.stop>
            <slot>Default content</slot>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'BasicModal',
    data() {
        return {
            isVisible: true
        };
    },
    methods: {
        closeModal() {
            this.isVisible = false;
        }
    }
};
</script>
  
<style>
@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(25px)brightness(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadein ease-out 1s;
}

.modal-content {
    font-family: "aktiv-grotesk-extended", sans-serif;
    background: radial-gradient(#161616, #393939);
    border: 1px solid #1FAF3E;
    padding: 1rem 1rem;
    border-radius: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: calc(100vw - 4rem);
    max-height: 90%;
    overflow: auto;
}

a {
    color: #1FAF3E;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
</style>
  