<template>
    <div v-if="isVisible" class="wp-backdrop" @click="closewp">
        <div class="wp-content" @click.stop>
            <h1 style="font-size:5vw;margin:auto;">CETI AI <b style="font-size:1rem;margin-left:auto;opacity:0.2;">Litepaper v1, March 2024</b> </h1>
            <h2>Accelerating the advancement of decentralized artificial intelligence</h2>
            <div class="overview-section">
                <h4 style="color:green;">Overview</h4>
                <p>At ceτi AI, our mission is to accelerate the advancement of decentralized artificial intelligence. In pursuit of this goal, we build globally distributed, high-performance, scalable AI infrastructure for developers and decentralized AI networks
                    that enables them to rapidly outpace the capabilities and reach of centralized AI. Through partnership and technical innovation we are committed to improving the accessibility of AI, the efficiency of AI infrastructure, and contributing
                    to the growth of a more decentralized and diverse AI ecosystem</p>
                <b style="color:green;">The problem with centralized AI today</b>
                <p>We have assembled here, as futurists, builders, creators, and visionaries, who, at our core, hold the conviction that the inexorable creation of artificial general intelligence will engender a flourishing of human potential and the elevation
                    of human civilization to unprecedented heights. However, the advancement of artificial intelligence toward this outcome currently confronts three significant challenges that demand our attention:</p>
                <b style="color:green;">Centralization of infrastructure</b>
                <p>The current concentration of AI infrastructure in the hands of a few large tech companies presents a significant challenge for the broader AI ecosystem. This centralization not only creates barriers to entry for independent developers, researchers,
                    and startups but also consolidates control over the direction and priorities of AI development. It limits access to high-quality computing resources to a select few, hindering innovation and progress in the field</p>
                <b style="color:green;">Inefficient resource utilization</b>
                <p>Existing AI infrastructure is characterized by significant inefficiencies in how resources are allocated and utilized. Despite the immense computing power available, much of it is underused or locked into silos, inaccessible to those who could
                    leverage it for groundbreaking research or applications. This inefficiency leads to inflated costs, waste, and an overall slower advancement of the industry.</p>
                <b style="color:green;">Centralization of development</b>
                <p>The development of AI technologies is predominantly centralized, with major projects and initiatives being driven by the same entities that control the infrastructure. This centralization stifles the growth and diversity of AI applications
                    by limiting the perspectives and approaches that are brought to bear on AI problems. It narrows the field of innovation, privileging certain types of solutions over others, and reduces the opportunity for breakthroughs that could come
                    from a more diverse set of contributors.</p>
            </div>
            <div class="solution-section">
                <h4>The solution</h4>
                <p>Infrastructure that connects every human with every AI, everywhere in the world
                </p>
                <p>The ceτi AI solution to centralized infrastructure is to build a globally distributed, high-performance, scalable AI infrastructure network that democratizes access to compute. This approach breaks down the barriers erected by the centralization
                    of infrastructure, allowing independent developers, researchers, and startups to access state-of-the-art computing resources. Global distribution ensures that these resources are not only available to a wider audience but also resilient
                    and capable of meeting the demands of various AI applications.</p>
                <b style="color:green;">Infrastructure that is itself, intelligent</b>
                <p>The ceτi AI solution to inefficient resource utilization is to create a cloud compute layer that intelligently allocates and maximally utilizes all available resources in the ceτi AI Infrastructure Network. Our ceτi AI Intelligent Compute
                    Fabric optimally matches unused computing power with both developers and decentralized AI networks. By optimizing the overall use of resources across the network, ceτi AI produces economically useful work at all times, reducing waste,
                    and lowering the barriers to entry for AI development, thereby accelerating innovation and reducing costs for everyone.</p>
                <b style="color:green;">Infrastructure that is purpose-built for accelerating decentralized AI</b>
                <p>The ceτi AI solution to centralized AI development is to foster a more diverse and innovative development ecosystem by using the ceτi AI Infrastructure Network and Intelligent Compute Fabric to power decentralized AI networks such as Bittensor,
                    Render, Akash, and more. Our solution allows developers from around the world to contribute to and benefit from AI advancements, regardless of their affiliation with major tech companies. It encourages a multitude of perspectives and approaches
                    to AI problems from a variety of decentralized communities, enhancing the diversity of AI applications and promoting breakthroughs from unexpected quarters.</p>
    
            </div>
            <div class="ecosystem-section">
                <h4>The CETI token and ecosystem</h4>
                <p>The CETI token is the lifeblood of the ceτi AI ecosystem, designed to fuel the growth of decentralized AI infrastructure and reward community participation. The CETI tokenomics are designed to fund the expansion of our infrastructure and the
                    development of decentralized AI networks and applications and to accrue the value of economically useful compute to token holders in a sustainable way while supporting both the Network and the broader adoption of decentralized AI technologies.</p>
                <b style="color:green;">CETI Token Allocation</b>
                <p>90% (18,900,000 CETI) paired with wTAO at launch. 10% (2,100,000 CETI) Team tokens divided as follows: Half (1,050,000 CETI) for team and contributors vesting in Hedgey and/or Sablier, and half (1,050,000 CETI) for Team Operations fully vested.</p>
                <b style="color:green;">Our flywheel</b>
                <img src="operaiton.png" alt="">
                <p>Our tokenomics are designed to create a virtuous cycle of growth that powers the expansion of our infrastructure at a sustainable rate and support for decentralized AI. We start by capturing value from volatility in the form of taxes. This
                    builds a treasury of tax reserves that are earmarked to be sold during times of market expansion.</p>
                <ul>
                    <li>As the market cap expands we will maintain a consistently increasing ratio of operational capital to high watermark market cap. Temporarily this increases the circulating supply and dilutes the revenue share but as this capital is converted
                        into infrastructure it will generate proportionately more revenue per market cap due to economies of scale.</li>
                    <li>This capital is then converted into our intelligent infrastructure network by our team. As we expand our infrastructure the revenue per market cap should consistently outperform the previous high before the tax reserve liquidation.</li>
                    <li>As our infrastructure earns revenue it will be compounded back to an in-kind form of on-chain capital and distributed by our revenue share system. This creates buy pressure on the CETI asset which systematically applies pressure on the
                        price.
                    </li>
                </ul>
                <p>This flywheel continues until there is no more economic value in expanding our infrastructure. The tax rate can be adjusted to ensure we never run out of taxes and can protect those in our revenue share system. If AI infrastructure is saturated
                    then CETI has accomplished its mission.</p>
                <b style="color:green;">Revenue Share System</b>
                <p>The revenue share system is how profits after operation expenses are returned to token holders. Our goal here is to identify different avenues for engagement that the project needs and to drive that engagement by pairing a higher share of
                    the revenue to the things the project needs more of. An example above is that we will need delegated capital for various DePIN networks to pair with our infrastructure before it can be brought online. There will be other qualifying criteria
                    that will evolve over time according to the project needs.</p>
    
            </div>
            <div style="team-section">
                <h4>The team</h4>
                <p>At the core of ceτi AI's innovation and success is a diverse and experienced team of professionals, dedicated to reshaping the future of artificial intelligence. With a rich blend of industry expertise, academic excellence, visionary thinking,
                    and a drive to achieve ambitious goals no matter the challenge, our team is committed to democratizing AI through decentralization.
                </p>
                <b>Leadership</b>
                <b class="title">Dennis Jarvis, CEO</b>
                <p>As the former CEO of Bitcoin.com and a management veteran of Apple, Dennis Jarvis brings a tremendous depth of leadership experience in technology and a profound passion for the transformative power of blockchain technology. His vision for
                    ceτi AI extends beyond merely advancing decentralized AI; it is to ensure that these advancements serve to empower individuals and communities across the globe, fostering a future where technology elevates all aspects of human life.</p>
                <b class="title">Aaron Smith-Hayes, CIO</b>
                <p>Aaron Smith-Hayes has cultivated a 25-year career in software development, honing his skills in game development, enterprise-level solutions, and blockchain technology. At Defy Development Corp, his ongoing leadership exemplifies a commitment
                    to excellence, prioritizing customer satisfaction, and delivering innovative solutions tailored to specific needs. His expertise has been pivotal in the development and deployment of blockchain infrastructure and DeFi protocols, enriching
                    the technology portfolio for customers and contributing to the company's strategic success. Aaron's current work on integrating AI and blockchain technology is guided by a vision to enhance project efficacy and introduce pioneering solutions
                    that meet the evolving demands of his stakeholders, demonstrating a forward-thinking approach to technological advancement.
                </p>
                <b class="title">Austin Spencer, CFO</b>
                <p>Austin Spencer is a distinguished financial leader with over 17 years experience in corporate and real estate finance. Beginning his career at ING, Austin quickly realized his entrepreneurial spirit, venturing into the realm of real estate
                    finance where he founded a highly successful enterprise that set new standards in the industry. A true visionary, Austin is also co-founder of Defy Development Corp., a pioneering force in the realms of blockchain and artificial intelligence.
                    Under his guidance, Defy Development Corp. has spearheaded the development of over 100 groundbreaking projects, revolutionizing industries and reshaping the future of technology.
                </p>
                <b class="title">Tony Evans, Chief Strategy Officer</b>
                <p>Tony Evans is the CEO of RATE Group, a distinguished crypto advisory firm with over 8 years of experience in crypto advice and decades dedicated to high-net-worth financial guidance. With a portfolio surpassing $200M in global assets and initiatives,
                    RATE Group stands as a beacon of expertise and trust. Renowned for its unparalleled skills in structuring, capital raising, OTC, Web3, NFT, and AI, the company has a commanding presence across Asia, Europe, the Middle East, and Africa.
                    Skillfully bridging the divide between physical, digital, and AI assets, RATE Group serves both discerning investors and visionary entrepreneurs. True to its pioneering spirit, RATE Group recently launched a trailblazing service to resurrect
                    and turn around failed crypto, NFT, and AI ventures.</p>
    
                <b class="title">LogrisTheBard, CSO</b>
                <p>Logris' technical background straddles AI, cloud computing, and crypto. He has applied his PhD in AI to everything from cognitive architectures to spam filters to sentiment analysis to LLM training at global scale. In his web2 background he
                    has worked as a software architect on big data processing, streaming computation, and microservice development for some of the largest tech companies in the world and on IoT systems spanning billions of devices and high volume data sources
                    like Twitter firehoses. Since then he has applied those skills to lead multiple companies from AI prototype to acquisition, adding over $1B to valuation of his latest enterprise and bringing process maturity and engineering excellence
                    to rapidly expanding teams. Logris is also a crypto veteran, advisor to several crypto projects, frequent technical resource to multiple VCs, and the cofounder and lead technical resource of Tokenomics Explained which makes him an actual
                    professional tokenomicist. Combined, Logris has everything it takes to breathe life into decentralized AI: in-depth knowledge of how AI processes work and the cloud resources they need, a deep and wide background in everything necessary
                    to make a cloud compute platform scale globally, and a truly formidable background in crypto that should make anyone stand up and take notice.</p>
    
                <b>Our philosophy</b>
                <p>United by a shared belief in the power of decentralized technology, the ceτi AI team is dedicated to creating a platform that is open, accessible, and beneficial for all. We prioritize innovation, collaboration, and community involvement,
                    believing that the best solutions arise from diverse perspectives and collective effort.</p>
    
                <b>Contributing to the ecosystem</b>
                <p>Beyond developing the ceτi AI infrastructure, our team actively contributes to the broader decentralized AI ecosystem through research, open-source and partner projects, thought leadership, and community engagement. We are passionate about
                    fostering a vibrant community of developers, researchers, and enthusiasts who share our vision for a decentralized AI future</p>
                <b>Join us</b>
                <p>As we continue to grow and push the boundaries of what's possible, we're always looking for talented individuals who share our passion for innovation and decentralization. If you're interested in joining our mission, we'd love to hear from
                    you.
                </p>
    
            </div>
            <div class="roadmap-section">
                <h4>Future roadmap-section</h4>
                <p>At ceτi AI, we are not just building technology; we are crafting the future of artificial intelligence. Our roadmap is a path that leads us towards a world where AI is universally accessible, decentralized, and serves as a catalyst for human
                    potential. Here are the first key milestones that pave our journey:</p>
                <ul>
                    <li>Launch the ceτi AI Infrastructure Network with Intelligent Compute Fabric</li>
    
                    <p>Embarking on our mission, we will roll out the ceτi AI Infrastructure Network with Intelligent Compute Fabric, a pivotal moment where our vision starts to materialize, providing the backbone for decentralized AI networks. This step is
                        more than an achievement; it's our commitment made manifest in the first step on our journey.</p>
                    <p>What does success look like? We have deployed NVIDIA hardware at pilot-scale, managed by our first release of ceτi AI Intelligent Compute Fabric, and we are providing compute to the Bittensor decentralized AI network.</p>
                    <li>Introduce CETI Token Yield Delegation Protocol</li>
                    <p>With the CETI token yield delegation, we're not just launching a feature; we're pioneering a new way for our community to engage with and benefit from the ceτi AI ecosystem. This innovation allows for a shared journey towards prosperity
                        and progress.</p>
                    <p>What does success look like? We have deployed smart contracts that enable CETI holders to share in our revenue and allocate delegated capital where it is most needed to empower our AI infrastructure network.</p>
                    <li>Expand to our premier data center</li>
                    <p>The transition of our hardware and compute solutions to our inaugural data center marks a leap in capability and reach. It's a stride towards scalability, setting the stage for global impact.</p>
                    <p>What does success look like? We have completed final site selection and acquisition, prepped the site, deployed a scaled-up, full-site installation of NVIDIA hardware managed by ceτi AI Intelligent Compute Fabric, and we are providing
                        compute to multiple decentralized AI networks.
                    </p>
                    <li>Global network expansion</li>
                    <p>Our ambition stretches far beyond single nodes; we envision a worldwide lattice of data centers providing edge resources to AI developers and users everywhere. This global scale-up is our pledge to democratize access to decentralized AI,
                        making it a universal resource.</p>
                    <p>What does success look like? We have scaled up our single-site installation to a network of data centers, we are running hardware from multiple providers including both NVIDIA and rapidly developing market alternatives, all managed by
                        the global-scale version of ceτi AI Intelligent Compute Fabric, and we are providing compute to multiple decentralized AI networks as well as to AI developers, teams, and researchers via bespoke relationships.</p>
    
                </ul>
            </div>
            div.vision
            <div class="vision">
                <h4>Our vision</h4>
                <p>As we advance through these milestones, our horizon is clear—a world where decentralized AI not only exists but thrives, laying the groundwork for the emergence of Artificial General Intelligence (AGI) that embodies our core principles of
                    accessibility, freedom, and universality. In this future, AGI evolves from decentralized AI foundations, unshackled and universally accessible to all, ensuring that the vast potential of AGI benefits humanity as a whole. We see ceτi AI
                    as a cornerstone in this new era, not just accelerating human progress, but also guiding the ethical and equitable development of AGI. Our path is one of bold innovation and solidarity, inviting all who share our dream to join in crafting
                    a future where AI—and eventually AGI—enriches every sentient life, human or otherwise, reflecting our deepest values and aspirations.
                </p>
            </div>
            <div class="involvedc">
                <h4>Get involved with ceτi AI</h4>
                <b>Join our community</b>
                <p>Become part of a pioneering movement reshaping the future of AI. Connect with us on X, join our Telegram for real-time discussions, or dive into deep conversations in our Discord. Your insights, questions, and enthusiasm are what drive us
                    forward.</p>
                <b>Support our mission</b>
    
                <p>Are you passionate about the potential of decentralized AI and AGI? Consider supporting ceτi AI by acquiring CETI tokens, participating in our network, or contributing to our infrastructure. Your support accelerates our journey towards an
                    accessible, ethical AGI.</p>
                <b>Collaborate on development</b>
                <p>For developers and enthusiasts eager to contribute to cutting-edge AI projects, ceτi AI offers numerous opportunities. Whether it's developing on the ceτi AI Intelligent Compute Fabric, contributing to our open-source projects, or integrating
                    your decentralized AI network with ours, your skills can help shape the future of AI. Contact us via our socials or at devs@taoceti.ai.</p>
                <b>Spread the word</b>
                <p>Help raise awareness about the importance of decentralized and ethical AI development. Share our mission on social media, write about us, or talk about ceτi AI in your networks. Every conversation helps us grow stronger.</p>
                <b>Stay informed</b>
                <p>The world of decentralized AI moves fast. Keep up with the latest from ceτi AI by subscribing to our newsletter at http://taoceti.ai.. Receive updates on our progress, insights into the world of decentralized AI, and exclusive invitations
                    to ceτi AI events.</p>
                <b>Contact us</b>
                <p>Have ideas, questions, or want to propose a partnership? We’re all ears. Reach out to us at admin@taoceti.ai. Together, we can explore the limitless possibilities of decentralized AI and AGI
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'BasicWp',
    data() {
        return {
            isVisible: true
        };
    },
    methods: {
        closewp() {
            this.isVisible = false;
        }
    }
};
</script>
  
<style lang="scss">
.overview-section {
    letter-spacing: 2px;
    h4 {
        font-size: 2rem;
        margin-bottom: unset;
    }
}

.solution-section {
    letter-spacing: 2px;
    h4 {
        font-size: 2rem;
        margin-bottom: unset;
    }
}

.ecosystem-section {
    letter-spacing: 2px;
    h4 {
        font-size: 2rem;
        margin-bottom: unset;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wp-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(25px)brightness(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadein ease-out 1s;
}

.wp-content {
    font-family: "aktiv-grotesk-extended", sans-serif;
    padding: 1rem 1rem;
    width: 1024px;
    max-width: calc(100v2 - 2rem);
    height: calc(80vh - 2rem);
    overflow: auto;
}

a {
    color: #1FAF3E;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
</style>
  