<template>
    <Modal v-if="showModal" @close="showModal = false">
        <p class="modal-header">Thank You For Your Interest!</p>
        <p class="subheader">We will keep you updated on our progress.</p>
        <p class="subheader">In the meantime, feel free to join our communities on Discord and Telegram!</p>
        <div class="button-wrapper" style="text-align:center;">
            <a target="_blank" :href="discordLink"><img src="~/@/assets/images/discord-icon.svg" alt=""></a>
            <a target="_blank" :href="telegramLink"><img style="width: 23px; height: 23px; padding-left: 2rem;"
                    src="~/@/assets/images/telegram.svg" alt=""></a>
        </div>
        <p style="text-align:center;">You can reach us at <a href="mailto:admin@taoceti.ai">admin@taoceti.ai</a></p>
    </Modal>

    <Wp v-if="showWp" @close="showWp = false">
    </Wp>

    <Modal v-if="showErrorModal" @close="showErrorModal = false">
        <p class="modal-header">Thank You For Your Interest!</p>
        <p class="subheader">The email you enetered was either invalid, or we already have you!</p>
        <p class="subheader">If the latter is the case, you can look forward to hearing from us. Also feel free to check
            us out on Discord and Telegram!</p>
        <div class="button-wrapper" style="text-align:center;">
            <a target="_blank" :href="discordLink"><img src="~/@/assets/images/discord-icon.svg" alt=""></a>
            <a target="_blank" :href="telegramLink"><img style="width: 23px; height: 23px; padding-left: 2rem;"
                    src="~/@/assets/images/telegram.svg" alt=""></a>
        </div>
        <p style="text-align:center;">You can reach us at <a href="mailto:admin@taoceti.ai">admin@taoceti.ai</a></p>
    </Modal>

    <div class="content-wrapper-parent" :style="{ height: windowHeight + 'px' }">
        <div class="background-content">
            <div class="internal-image-content">
                <Spline class="spline-component notVisable" :class="{ makeVisable: divsToShow.spline === true }"
                    :mousePosition="mousePosition" @ready="displayDivsSequentially"></Spline>
            </div>

            <div class="grid-content" @mousemove="onMouseMove" @mouseleave="onMouseLeave" ref="gridContainer">
                <GridComponent style=""></GridComponent>
            </div>
        </div>
        <div class="peripheral-content" :style="{ height: windowHeight + 'px' }">
            <div class="header ">
                <div class="icon"><img src="~/@/assets/images/Logo(1).svg" alt=""></div>
                <div @click="showModal = !showModal" class="button-top notVisable" style="color:white;"
                    :class="{ makeVisable: divsToShow.contactCta === true }">
                    <p>CONTACT</p>
                </div>
            </div>
            <div class="socials notVisable" :class="{ makeVisable: divsToShow.socials === true }">
                <a target="_blank" href="https://x.com/ceti_ai"><img src="~/@/assets/images/X_logo_2023_original.svg"
                        alt=""></a>
                <a target="_blank" :href="discordLink"><img src="~/@/assets/images/discord-icon.svg" alt=""></a>
                <a target="_blank" :href="telegramLink"><img src="~/@/assets/images/telegram.svg" alt=""></a>
                <a target="_blank" href="https://etherscan.io/address/0x1bfce574deff725a3f483c334b790e25c8fa9779"><img
                        src="~/@/assets/images/etherscan.svg" alt=""></a>
                <a target="_blank"
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0x1140201364600b0016f35c5dd32e2269229ac3d6"><img
                        src="~/@/assets/images/dextools.svg" alt=""></a>
                <a target="_blank" href="https://www.youtube.com/@ceti_ai"><img style="width:28px;filter:invert(1);"
                        src="~/@/assets/images/youtube.svg" alt=""></a>
                <a target="_blank" href="https://www.linkedin.com/company/ceti-ai/"><img
                        style="width:32px;filter:invert(1);" src="~/@/assets/images/linkedin.svg" alt=""></a>
                <a target="_blank" href="https://ceti-ai.medium.com/ce%CF%84i-ai-da5a5d3aa534"
                    @click="showWp = true"><img src="~/@/assets/images/litepaper.svg" alt=""></a>

            </div>
        </div>
        <div class="main-content">
            <div class="main-text-wrapper">
                <div class="header-text notVisable" :class="{ makeVisable: divsToShow.heroTextHeading === true }">
                    <h1>Accelerating the advancement of decentralized artificial intelligence.</h1>
                </div>
                <div class="desc-text tk-aktiv-grotesk-extended notVisable"
                    :class="{ makeVisable: divsToShow.heroTextDesc === true }">
                    <p>At ceτi, our mission is to accelerate the advancement of decentralized artificial intelligence.
                        In pursuit of this goal, we build globally distributed, high-performance, scalable AI
                        infrastructure for developers and distributed networks
                        that enables them to rapidly outpace the capabilities and reach of centralized AI.

                    </p>
                </div>
            </div>
            <div class="cta-wrapper notVisable" :class="{ makeVisable: divsToShow.emailCta === true }">
                <div class="cta-input-area">
                    <div class="cta-input-title tk-aktiv-grotesk-extended ">
                        <p style="color:white;">FIND OUT MORE</p>
                    </div>
                    <div class="cta-input-row">
                        <input type="email" v-model="email" placeholder="ENTER EMAIL" class="inputbox">
                        <div class="submit">
                            <RecaptchaForm :email="email" @success="submitEmail" @error="rejectEvilRobot">
                            </RecaptchaForm>
                        </div>
                    </div>
                    <p class="tk-aktiv-grotesk-extended address">
                        128 City Road, London, United Kingdom, EC1V 2NX
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spline from './components/spline.vue';
import Modal from './components/modal.vue';
import Wp from './components/wp.vue';
import RecaptchaForm from './components/recaptcha.vue';
import GridComponent from './components/GridComponent.vue';

export default {
    name: 'App',
    data() {
        return {
            windowHeight: 0, // Add this to store the window height
            showModal: false,
            showWp: false,
            showErrorModal: false,
            mousePosition: { x: 0, y: 0 },
            email: null,
            discordLink: "https://discord.gg/ceti-ai",
            telegramLink: "https://t.me/ceti_ai",
            divsToShow: {
                spline: false,
                heroTextHeading: false,
                heroTextDesc: false,
                contactCta: false,
                emailCta: false,
                socials: false,

            }
        };
    },
    methods: {
        updateWindowHeight() {
            // Set the window height in pixels
            this.windowHeight = window.innerHeight;
        },
        onMouseMove(event) {
            this.mousePosition.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.mousePosition.y = -(event.clientY / window.innerHeight) * 2 + 1;

            // Update grid glow based on mouse position over the entire window
            const grid = this.$refs.gridImage;
            if (grid) {
                const rect = grid.getBoundingClientRect();
                const x = event.clientX - rect.left; // x position within the element.
                const y = event.clientY - rect.top; // y position within the element.
                grid.style.setProperty('--x', `${x}px`);
                grid.style.setProperty('--y', `${y}px`);
            }
        },

        submitEmail() {
            this.showModal = true;
            this.email = null;
        },

        rejectEvilRobot() {
            this.showErrorModal = true;
        },
        displayDivsSequentially() {
            const divKeys = Object.keys(this.divsToShow);
            divKeys.forEach((key, index) => {
                setTimeout(() => {
                    this.divsToShow[key] = true;
                }, index * 1000); // Adjust timing as needed
            });
        },
    },
    async mounted() {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('resize', this.updateWindowHeight); // Add this
        this.updateWindowHeight(); // Add this

    },
    beforeUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('resize', this.updateWindowHeight); // Add this

    },
    components: {
        Spline,
        Modal,
        Wp,
        RecaptchaForm,
        GridComponent
    },
};
</script>

<style global>
.grecaptcha-badge {
    visibility: hidden;
}
</style>

<style lang="scss">
@import url("https://p.typekit.net/p.css?s=1&k=rxg7sbq&ht=tk&f=26907.26908.26909.26910.26913.26914&a=7127626&app=typekit&e=css");

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/12d926/00000000000000007753ca53/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/12d926/00000000000000007753ca53/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/12d926/00000000000000007753ca53/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 300;
    font-stretch: normal;
}

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/da7e7c/00000000000000007753ca4d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/da7e7c/00000000000000007753ca4d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/da7e7c/00000000000000007753ca4d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
}

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/877797/00000000000000007753ca31/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/877797/00000000000000007753ca31/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/877797/00000000000000007753ca31/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/bcd6b3/00000000000000007753ca47/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/bcd6b3/00000000000000007753ca47/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/bcd6b3/00000000000000007753ca47/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/23060c/00000000000000007753ca5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/23060c/00000000000000007753ca5a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/23060c/00000000000000007753ca5a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "aktiv-grotesk-extended";
    src: url("https://use.typekit.net/af/f607d9/00000000000000007753ca41/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/f607d9/00000000000000007753ca41/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/f607d9/00000000000000007753ca41/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
}

.address{
    margin-bottom:-2rem;
    font-size:0.5rem;
}

.spline-component {
    opacity: 1;
}

.tk-aktiv-grotesk-extended {
    font-family: "aktiv-grotesk-extended", sans-serif;
}

.modal-header {
    font-weight: 500;
    font-size: 1.5rem;
    margin: 1rem auto;
    text-align: center;
    border-bottom: 1px solid #1FAF3E;
}

.notVisable {
    opacity: 0;
}

.makeVisable {
    opacity: 1;
    transition: opacity 3s ease-out;
}

@keyframes shrinkToNormal {
    from {
        transform: scale(3);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.whitepaper {
    cursor: pointer;
    width: 23px;
    height: 23px;
}

.fadein-animation {
    animation: fadein 2s ease 1;
}

.grid-content {
    background: rgba(0, 0, 0, 0.6);
}

.grid-content img {
    transition: filter 0.3s ease;
}

.grid-content:hover img {
    filter: brightness(2);
    /* Increase brightness, adjust value as needed */
}

.content-wrapper-parent {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .background-content {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(100px)brightness(0.9);

        .internal-image-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;

            img {
                opacity: 0.5;
                width: 100%;
                height: auto;
                display: none;
                z-index: 0;
            }

            iframe {
                user-select: none;
                height: 150vh;
                width: 150vw;
                top: -25vh;
                left: -25vw;
                position: fixed;
                z-index: 5;
                opacity: 0.5;
            }
        }

        .grid-content {
            position: fixed;
            top: 0px;
            height: 100vh;
            width: 100vw;
            right: 0px;
            display: flex;
            flex-flow: column;
            z-index: 0;

            &::before {
                content: '';
                left: -20%;
                bottom: -20vh;
                position: absolute;
                width: 90vw;
                transform: rotate(25deg);
                height: 50vh;
                opacity: 0.01;
                background: linear-gradient(transparent, #FF7A00, #EA532A);
            }

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .peripheral-content {
        position: absolute;
        display: flex;
        z-index: 2;

        .header {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            position: absolute;
            z-index: 2;
            top: 4rem;
            left: 4rem;
            right: 4rem;
            width: calc(100vw - 8rem);

            .icon {
                img {
                    width: 100px;
                    height: auto;
                    animation: shrinkToNormal 1s ease forwards;
                }
            }

            .button-top {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: relative;
                padding: 1.5rem 1rem;
                cursor: pointer;
                background-image: url(assets/images/button-vector.svg);

                &::before {
                    position: absolute;
                    left: -18px;
                    top: 8px;
                    cursor: pointer;
                    background-image: url(assets/images/Vectorcircle.svg);
                    content: '';
                    width: 54px;
                    height: 54px;
                }

                p {
                    @extend .tk-aktiv-grotesk-extended;
                    margin: unset;
                    letter-spacing: 2px;
                }
            }
        }

        .socials {
            position: absolute;
            left: 4rem;
            bottom: 4rem;
            display: flex;
            flex-flow: column;
            gap: 2rem;

            a {
                display: flex;
                flex-flow: row;
                justify-content: center;
            }
        }
    }

    .main-content {
        display: flex;
        flex-flow: column;
        height: 700px;
        left: 10rem;
        width: calc(100vw - 28rem);
        position: fixed;
        top: calc(50vh - 300px);
        pointer-events: none;

        .main-text-wrapper {
            user-select: none;

            .header-text {
                position: relative;
                padding: 2rem;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-image: url(assets/images/top-bar.svg);
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    width: 100%;
                    height: 100%;
                    background-position: bottom center;
                    background-repeat: no-repeat;
                    background-image: url(assets/images/bottom-bar.svg);
                }

                h1 {
                    margin: unset;
                    font-weight: 400;
                    @extend .tk-aktiv-grotesk-extended;
                }

                font-size: 1.8vw;
            }

            .desc-text {
                padding: 2rem;
                width: 40vw;

                p {
                    font-weight: 300;
                    line-height: 1.5;
                    letter-spacing: 2px;
                    font-size: 0.8vw;
                }
            }
        }

        .cta-wrapper {
            pointer-events: all;
            position: absolute;
            height: 300px;
            width: 800px;
            bottom: 0rem;
            right: -8rem;
            z-index: 11;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(assets/images/orangevec.svg);

            .cta-input-area {
                position: absolute;
                left: 2rem;
                bottom: 4rem;
                letter-spacing: 2px;

                .cta-input-row {
                    display: flex;
                    flex-flow: row;
                }

                .inputbox {
                    @extend .tk-aktiv-grotesk-extended;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    border: unset;
                    margin-right: -1rem;
                    padding-right: 2rem;
                    width: 360px;

                    &::placeholder {
                        font-size: 14px;
                        margin-right: 4rem;
                    }
                }

                .submit {
                    @extend .tk-aktiv-grotesk-extended;
                    padding: 1rem 1.2rem;
                    background: #161616;
                    color: white;
                    letter-spacing: 2px;
                    position: relative;
                    border-radius: 0.5rem;
                    border: unset;

                    &::before {
                        position: absolute;
                        left: 12px;
                        top: 9px;
                        background-image: url(assets/images/Vectorcircle.svg);
                        content: '';
                        background-size: contain;
                        width: 36px;
                        height: 36px;
                    }
                }

                .submit-button {
                    @extend .tk-aktiv-grotesk-extended;
                    background: #161616;
                    color: white;
                    letter-spacing: 2px;
                    border-radius: 0.5rem;
                    border: unset;
                    font-size: 14px;
                }
            }
        }
    }
}

body {
    margin: unset;
    position: relative;
}

p {}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #D8D8D5;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background: radial-gradient(#161616, #393939);
}

@media screen and (max-height: 580px) {
    .cta-wrapper {
        display: none;
    }
}

@media screen and (max-width:1860px) {
    .content-wrapper-parent {
        display: flex;
        flex-flow: column;
        justify-content: center;

        .background-content {
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0px;
            left: 0px;

            .internal-image-content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;

                img {
                    opacity: 0.5;
                    width: 100%;
                    height: auto;
                    display: none;
                    z-index: 0;
                }

                iframe {
                    user-select: none;
                    height: 150vh;
                    width: 150vw;
                    top: -25vh;
                    left: -25vw;
                    position: fixed;
                    z-index: 5;
                    opacity: 0.5;
                    animation: fadein 5s ease 1;
                }
            }

            .grid-content {
                position: fixed;
                top: 0px;
                height: 100vh;
                width: 100vw;
                display: flex;
                flex-flow: column;
                z-index: 0;

                &::before {
                    content: '';
                    left: -20%;
                    bottom: -15%;
                    position: absolute;
                    width: 90vw;
                    transform: rotate(25deg);
                    height: 50vh;
                    opacity: 0.01;
                    background: linear-gradient(transparent, #FF7A00, #EA532A);
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .peripheral-content {
            position: absolute;
            display: flex;
            z-index: 2;

            .header {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                position: absolute;
                z-index: 2;
                top: 2rem;
                left: 2rem;
                right: 2rem;
                width: calc(100vw - 3rem);

                .icon {}

                .button-top {
                    margin-right: 0.5rem;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: relative;
                    padding: 1.5rem 1rem;
                    margin-top: -1.5rem;
                    background-image: url(assets/images/button-vector.svg);

                    &::before {
                        position: absolute;
                        left: -18px;
                        top: 8px;
                        background-image: url(assets/images/Vectorcircle.svg);
                        content: '';
                        width: 54px;
                        height: 54px;
                    }

                    p {
                        margin: unset;
                        letter-spacing: 2px;
                    }
                }
            }

            .socials {
                position: fixed;
                left: 2rem;
                bottom: 2rem;
                display: flex;
                flex-flow: column;
                gap: 2rem;

                a {
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                }
            }
        }

        .main-content {
            display: flex;
            flex-flow: column;
            height: auto;
            left: 6rem;
            width: calc(100vw - 12rem);
            position: fixed;
            top: calc(30vh - 150px);
            pointer-events: none;

            .main-text-wrapper {
                user-select: none;

                .header-text {
                    position: relative;
                    padding: 2rem;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-image: url(assets/images/top-bar.svg);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        width: 100%;
                        height: 100%;
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        background-image: url(assets/images/bottom-bar.svg);
                    }

                    h1 {
                        margin: unset;
                        font-weight: 400;
                    }

                    font-size: 1.8vw;
                }

                .desc-text {
                    padding: 2rem;
                    width: auto;

                    p {
                        font-weight: 300;
                        line-height: 1.5;
                        letter-spacing: 2px;
                    }
                }
            }

            .cta-wrapper {
                pointer-events: all;
                position: absolute;
                height: 300px;
                width: 800px;
                bottom: -16rem;
                right: -12rem;
                z-index: 11;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(assets/images/orangevec.svg);

                .cta-input-area {
                    position: absolute;
                    left: 2rem;
                    bottom: 4rem;
                    letter-spacing: 2px;

                    .cta-input-row {
                        display: flex;
                        flex-flow: row;
                    }

                    .inputbox {
                        padding: 1rem;
                        border-radius: 0.5rem;
                        border: unset;
                        margin-right: -1rem;
                        padding-right: 2rem;
                        width: 350px;

                        &::placeholder {
                            font-size: 14px;
                            margin-right: 4rem;
                        }
                    }

                    .submit {
                        padding: 1rem 1.2rem;
                        background: #161616;
                        color: white;
                        letter-spacing: 2px;
                        position: relative;
                        border-radius: 0.5rem;
                        border: unset;

                        &::before {
                            position: absolute;
                            left: 12px;
                            top: 9px;
                            background-image: url(assets/images/Vectorcircle.svg);
                            content: '';
                            background-size: contain;
                            width: 36px;
                            height: 36px;
                        }
                    }

                    .submit-button {
                        background: #161616;
                        color: white;
                        letter-spacing: 2px;
                        border-radius: 0.5rem;
                        border: unset;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:810px) {
    .content-wrapper-parent {
        display: flex;
        flex-flow: column;
        justify-content: center;

        .background-content {
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0px;
            left: 0px;

            .internal-image-content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: center;

                img {
                    opacity: 0.5;
                    width: 100%;
                    height: auto;
                    display: none;
                    z-index: 0;
                }

                iframe {
                    user-select: none;
                    height: 120vh;
                    width: 120vw;
                    top: -10vh;
                    left: -10vw;
                    position: fixed;
                    z-index: 5;
                    opacity: 0.5;
                    animation: fadein 5s ease 1;
                }
            }

            .grid-content {
                position: fixed;
                top: 0px;
                height: 100vh;
                width: 100vw;
                display: flex;
                flex-flow: column;
                z-index: 0;

                &::before {
                    content: '';
                    left: -20%;
                    bottom: -15%;
                    position: absolute;
                    width: 90vw;
                    transform: rotate(25deg);
                    height: 50vh;
                    opacity: 0.01;
                    background: linear-gradient(transparent, #FF7A00, #EA532A);
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .peripheral-content {
            position: fixed;
            display: flex;
            top: 0px;
            z-index: 2;

            .header {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                position: absolute;
                z-index: 2;
                top: 2rem;
                left: 2rem;
                right: 2rem;
                width: calc(100vw - 3rem);

                .icon {}

                .button-top {
                    margin-right: 0.5rem;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: relative;
                    padding: 1.5rem 1rem;
                    margin-top: -1.5rem;
                    background-image: url(assets/images/button-vector.svg);

                    &::before {
                        position: absolute;
                        left: -18px;
                        top: 10px;
                        background-image: url(assets/images/Vectorcircle.svg);
                        background-size: contain;
                        content: '';
                        width: 44px;
                        height: 44px;
                    }

                    p {
                        margin: unset;
                        font-size: 0.8rem;
                        letter-spacing: 2px;
                    }
                }
            }

            .socials {
                position: fixed;
                left: 2rem;
                bottom: 2rem;
                display: flex;
                flex-flow: row;
                gap: 2rem;

                a {
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                }
            }
        }

        .main-content {
            display: flex;
            flex-flow: column;
            height: auto;
            left: 2rem;
            width: calc(100vw - 4rem);
            position: fixed;
            top: calc(30vh - 100px);
            pointer-events: none;

            .main-text-wrapper {
                user-select: none;

                .header-text {
                    position: relative;
                    padding: 1rem;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-image: url(assets/images/top-bar.svg);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        width: 50%;
                        height: 100%;
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        background-image: url(assets/images/bottom-bar.svg);
                    }

                    h1 {
                        margin: unset;
                        font-weight: 400;
                        letter-spacing: 0.4vw;
                        line-height: 1;
                    }

                    font-size: 2vw;
                }

                .desc-text {
                    padding: 1rem;
                    width: auto;

                    p {
                        font-size: 0.8rem;
                        font-weight: 300;
                        line-height: 1.2;
                        letter-spacing: 2px;
                    }
                }
            }

            .cta-wrapper {
                pointer-events: all;
                position: absolute;
                height: 220px;
                width: 500px;
                bottom: -40vw;
                right: -1rem;
                z-index: 11;
                max-width: 90vw;
                max-height: 42vw;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(assets/images/orangevec.svg);

                .cta-input-area {
                    position: absolute;
                    left: 1rem;
                    bottom: 2.5rem;
                    letter-spacing: 2px;
                    font-size: 0.5rem;

                    .cta-input-row {
                        display: flex;
                        flex-flow: row;
                    }

                    .inputbox {
                        padding: 0.5rem;
                        border-radius: 0.5rem;
                        border: unset;
                        margin-right: -1rem;
                        padding-right: 2rem;
                        width: 32vw;
                        font-size: 0.5rem;

                        &::placeholder {
                            font-size: 0.5rem;
                            margin-right: 4rem;
                        }
                    }

                    .submit {
                        padding: 1rem 1.2rem;
                        background: #161616;
                        color: white;
                        letter-spacing: 2px;
                        position: relative;
                        border-radius: 0.5rem;
                        border: unset;
                        font-size: 0.5rem;

                        &::before {
                            position: absolute;
                            left: 10px;
                            font-size: 0.5rem;
                            top: 7px;
                            background-image: url(assets/images/Vectorcircle.svg);
                            content: '';
                            background-size: contain;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .submit-button {
                        background: #161616;
                        color: white;
                        letter-spacing: 2px;
                        border-radius: 0.5rem;
                        font-size: 0.5rem;
                        border: unset;
                        font-size: 0.5rem;
                    }
                }
            }
        }
    }
}
</style>./components/GridComponent.vue
