<template>
    <div>
        <input v-if="loading === false" value="SEND" class="submit-button" @click="onSubmit" type="submit">
        <div v-if="loading" class="loader"></div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'RecaptchaForm',
    props: ['email'],
    emits: ['success', 'error'],
    data() {
        return {
            isVisible: false,
            loading: false,
        };
    },
    methods: {
        async onSubmit() {
                const payload = {
                    email: this.email
                }
                let response;
                this.loading = true;
                await axios.post('https://api.taoceti.ai/emails/', payload)
                .then((res) => {
                    response = res;
                }).catch((error) => {
                    console.error('Error:', error);
                    return;
                });
                setTimeout(() => {
                    
                }, 1000);
                console.log(response)
                this.loading = false;
                if(response && response.status === 200) {
                    this.$emit('success');
                } else {
                    this.$emit('error');
                    console.log(response)
                }
        },
    },
}
</script>
<style lang="scss">
.loader {
    height: 1rem;
    width: 1rem;
    min-height: 1rem;
    min-width: 1rem;
    border-radius: 1rem;
    border-left: 4px solid #333;
    border-right: 4px solid transparent;
    animation: spin 3s ease infinite;
    transform-origin: center;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(3600deg);
    }
}
.tk-aktiv-grotesk-extended {
    font-family: "aktiv-grotesk-extended", sans-serif;
}

.submit-button {
    @extend .tk-aktiv-grotesk-extended;
    background: #161616;
    color: white;
    letter-spacing: 2px;
    border-radius: 0.5rem;
    border: unset;
    font-size: 14px;
}
</style>